import React, {useEffect, useState} from 'react';
import {Avatar, Dropdown, Layout, Menu} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import Background from '../../images/background.png';
import {getUser} from '../../utils/request';
import {whOne200} from "../../utils/image";

import '../../App.less';
import './index.scss'


const OrdinaryPage = (page, loginTic, jump) => {

    const [ordinaryPageShow, setOrdinaryPageShow] = useState(true)

    const [user, setUser] = useState({
        userId: undefined,
        image: undefined,
        name: '登录'
    })

    const {Header, Footer, Content} = Layout;

    const backgroundStyle = {
        backgroundImage: `url(${Background})`,
    }

    const index = () => (
        window.location.href = '/'
    );

    const userClick = () => {
        if (typeof (user.userId) === "undefined") {
            window.location.href = '/login'
        } else {
            window.location.href = '/user/' + user.userId
        }
    }

    const jumpHome = () => {
        if (typeof (user.userId) === "undefined" ) {
            window.location.href = '/'
        } else {
            window.location.href = '/user/' + user.userId
        }
    }

    const openGov = () => {
        window.open('http://beian.miit.gov.cn')
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <a rel="noopener noreferrer" href="/setting">
                    设置
                </a>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item>
                <a rel="noopener noreferrer" href="/api/loginOut">
                    退出
                </a>
            </Menu.Item>
        </Menu>
    )

    const userHtml = () => {
        if (typeof (user.userId) === "undefined") {
            return (
                <div className="ant-page-header-heading-extra">
                    <Avatar className="user-avatar" size="large" icon={<UserOutlined/>} src={whOne200(user.image)}/>
                    <i className="user-name" onClick={() => userClick()}>{user.name}</i>
                </div>
            )
        } else {
            return (
                <Dropdown overlay={menu} placement="bottomCenter" arrow>
                    <div className="ant-page-header-heading-extra">
                        <Avatar className="user-avatar" size="large" icon={<UserOutlined/>} src={whOne200(user.image)}
                                onClick={() => userClick()}/>
                        <i className="user-name">{user.name}</i>
                    </div>
                </Dropdown>
            )
        }
    }

    const baiduHm = () => {
        const script = document.createElement("script")
        script.text = `
                var _hmt = _hmt || [];
                (function() {
                    var hm = document.createElement("script");
                    // hm.src = "https://hm.baidu.com/hm.js?ed3a2c1e1cc47910edecaa83a42a4cfd"; // dev
                    hm.src = "https://hm.baidu.com/hm.js?7f462c060012ef9140e7a2d3b6910a10"; // pro
                    var s = document.getElementsByTagName("script")[0];
                    s.parentNode.insertBefore(hm, s);
                })();
              `
        document.body.appendChild(script)
    }

    useEffect(() => {
        if (ordinaryPageShow) {
            setOrdinaryPageShow(false)
            baiduHm()
            getUser(undefined, loginTic).then(res => {
                setUser({
                    userId: res.data.userId,
                    image: res.data.photo,
                    name: res.data.name
                })
                if (window.location.pathname === '/login') {
                    window.location.href = '/user/' + res.data.userId
                }
            }).catch(e => {
                if (e.type === 'then') {
                    if (e.res.code === 3 && jump !== undefined && jump === true) {
                        setTimeout(() => window.location.href = '/404', 1500)
                    }
                }
            }).finally(() => {
            })

        }
    })

    return (
        <div className="App ordinary-class" style={backgroundStyle}>
            <Layout>
                <Header className="site-layout-sub-header-background" style={{padding: 0}}>
                    <div className="ant-page-header ant-page-header-compact">
                        <div className="ant-page-header-heading">
                            <div className="ant-page-header-heading-left">
                                <span className="ant-page-header-heading-title" onClick={() => {
                                    jumpHome()
                                }}>主页</span>
                            </div>
                            {userHtml()}
                            <button id='jumpHomeId' onClick={jumpHome} style={{display: 'none'}}></button>
                        </div>
                    </div>
                </Header>
                <Content>
                    <div className="site-layout-background">
                        {page}
                    </div>
                </Content>
                <Footer className="footer-name" onClick={() => openGov()}>粤ICP备18123999号-1</Footer>
            </Layout>
        </div>
    )
};

export default OrdinaryPage;