import emoji from './emoji'

const Options = (width, updatePhoto) => {

    const toolbarMobile = ['emoji', 'upload', 'table', 'undo', 'redo', 'fullscreen'
        , {
            name: "more",
            toolbar: ['preview', 'export', 'help']
        }]
    const toolbarPc = ['emoji', 'headings', 'bold', 'italic', 'strike', 'link', '|', 'list', 'ordered-list', 'check', 'outdent', 'indent', '|', 'quote', 'line', 'code', 'inline-code', 'insert-after', 'insert-before', '|', 'upload', 'table', '|', 'undo', 'redo', '|', 'fullscreen', 'edit-mode', {
        name: "more",
        toolbar: ['preview', 'outline', 'export', 'help']
    }]

    const getHeight = (width) => {
        if (width > 767) {
            return 360
        } else {
            return 700
        }
    }

    const getToolbar = (width) => {
        if (width > 767) {
            return toolbarPc
        } else {
            return toolbarMobile
        }
    }

    const uploadImage = (files) => {
        let url = "//dev.file.kknotes.cn/Fj_EgO9nxqhK7sRcCB7dOoNko0lB?imageView2/1/w/80/h/80/q/75|imageslim"
        return {
            "msg": "",
            "code": 0,
            "data": {
                "errFiles": ['filename', 'filename2'],
                "succMap": {
                    "filename3": url,
                }
            }
        }
    }

    return {
        // cdn:'',
        // options:{
        //     cdn:'',
        // },
        minHeight: getHeight(width),
        width: '100%',
        mode: 'wysiwyg',
        typewriterMode: 'false',
        toolbar: getToolbar(width),
        toolbarConfig: {
            pin: true,
        },
        cache: {
            enable: false,
        },
        counter: {
            enable: true
        },
        theme: 'classic',
        hint: {
            emoji: emoji
        },
        preview: {
            hljs: {
                enable: true,
                style: 'xcode',
                lineNumber: true
            },
            markdown: {
                toc: true,
                paragraphBeginningSpace: false,
                sanitize: true
            },
            theme: {
                current: "light",
                path: '/dist/css/content-theme/light.css'
            }
        },
        upload: {
            accept: 'image/*',
            handler: updatePhoto,
        }
    }
}

export default Options;
