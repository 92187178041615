import React, {useEffect, useState} from 'react';
import ordinaryPage from "../../../components/ordinaryPage";
import {Col, Image, Row, Space} from 'antd';
import errorScss from './index.module.scss';


const images = [
    'https://gitee.com/LambdaExpression/collect/raw/master/egg/1.gif'
    , 'https://gitee.com/LambdaExpression/collect/raw/master/egg/2.gif'
    , 'https://gitee.com/LambdaExpression/collect/raw/master/egg/3.gif'
]

const Error404 = () => {

    const [image, setImage] = useState(false)


    useEffect(() => {
        setImage(images[new Date().getTime() % images.length])
    })

    return ordinaryPage(
        <Space className={errorScss.spaceClass} align="center">
            <div className={errorScss.spinDiv}>
                <Row justify="center">
                    <Col span={24} className={errorScss.title}>
                        <span className={errorScss.errorSpan}>遇到</span>
                        <span className={errorScss.errorSpan404}>404</span>
                        <span className={errorScss.errorSpan}>了，先放松一下吧~</span>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={24}>
                        <Image className={errorScss.imageClass} preview={false}
                               width={document.body.clientWidth > 767 ? window.innerWidth / 5 : window.innerWidth / 2}
                               src={image}
                        />
                    </Col>
                </Row>
            </div>
        </Space>
    )
}

export default Error404;