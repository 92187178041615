export function whOne80(image) {
    return check(image, '?imageView2/1/w/80/h/80/q/75|imageslim');
}

export function whOne100(image) {
    return check(image, '?imageView2/1/w/100/h/100/q/75|imageslim');
}

export function whOne200(image) {
    return check(image, '?imageView2/1/w/80/h/80/q/75|imageslim');
}

export function whOne300(image) {
    return check(image, '?imageView2/2/w/300/h/300/q/75|imageslim');
}

export function whTwo300(image) {
    return check(image, '?imageView2/2/w/300/h/300/q/75|imageslim');
}

export function whTwo500(image) {
    return check(image, '?imageView2/2/w/500/h/500/q/75|imageslim');
}

export function whTwo1000(image) {
    return check(image, '?imageView2/2/w/1000/h/1000/q/75|imageslim');
}

export function whTwo1200(image) {
    return check(image, '?imageView2/2/w/1200/h/1200/q/75|imageslim');
}

export function check(image, format) {
    if (image === null) {
        return '';
    }
    if (image === undefined) {
        return '';
    }
    if (typeof (image) === "undefined") {
        return '';
    }
    if (image.trim() === '') {
        return '';
    }
    return image + format;
}

