import VditorPreview from 'vditor/dist/method.min'
import './index.scss';
import ImgLoading from './img-loading2.svg';

const cdn = `/static/vditor@3.9.0`;
// const theme = "classic";
// const vOptions = {
//     options: {
//         anchor: 1,
//         hljs: {
//             enable: true,
//             style: 'xcode',
//             lineNumber: true
//         },
//         markdown: {
//             toc: true,
//             paragraphBeginningSpace: false,
//             sanitize: true,
//         },
//         theme: {
//             current: "light"
//         }
//     }
// }
//
// const Preview = (preview, outline, markDown) => {
//     VditorPreview.preview(document.getElementById(preview), markDown, {
//         speech: {
//             enable: true,
//         },
//         anchor: 1,
//         after() {
//             // if (window.innerWidth <= 768) {
//             //     return
//             // }
//             // const outlineElement = document.getElementById('outline')
//             // Vditor.outlineRender(document.getElementById('preview'),
//             //     outlineElement)
//             // if (outlineElement.innerText.trim() !== '') {
//             //     outlineElement.style.display = 'block'
//             // }
//             // VditorPreview.lazyLoadImageRender(document.getElementById('preview'))
//         },
//         lazyLoadImage: ImgLoading,
//         hljs: {
//             enable: true,
//             style: 'xcode',
//             lineNumber: true
//         },
//         options: {
//             theme: {
//                 current: "light"
//             },
//             markdown: {
//                 toc: true,
//                 paragraphBeginningSpace: false,
//                 sanitize: true,
//             },
//             preview:{
//                 markdown: {
//                     toc: true,
//                     paragraphBeginningSpace: false,
//                     sanitize: true,
//                 }
//             },
//         },
//         theme: {
//             current: "light"
//         },
//         markdown: {
//             toc: true,
//             paragraphBeginningSpace: false,
//             sanitize: true,
//         },
//     });
//     // Vditor.outlineRender(document.getElementById(preview), document.getElementById(outline))
// }

const Preview = (preview, outline, markDown) => {
    VditorPreview.preview(document.getElementById(preview), markDown, {
        cdn: cdn,
        speech: {
            enable: true,
        },
        anchor: 1,
        lazyLoadImage: ImgLoading,
        hljs: {
            enable: true,
            style: 'xcode',
            lineNumber: true
        },
        hint: {
            emojiPath: cdn + '/dist/images/emoji'
        },
        options: {
            theme: {},
        },
        theme: {
            current: "light",
            path: cdn + '/dist/css/content-theme'
        },
        preview: {
            theme: {
                current: "light",
                path: cdn + '/dist/css/content-theme'
            },
        },
        markdown: {
            toc: true,
            paragraphBeginningSpace: false,
            sanitize: true,
        },
    });
    VditorPreview.setContentTheme("light",cdn + '/dist/css/content-theme');
}

export default Preview;
