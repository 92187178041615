import React, {useEffect, useState} from 'react';
import {PlusOutlined, UserOutlined} from '@ant-design/icons';
import {Affix, Avatar, Button, Col, Input, Pagination, Row, Space, Spin, Tooltip} from 'antd';
import ordinaryPage from "../../components/ordinaryPage";
import noteListScss from "./index.module.scss";
import KKLabel from "../../module/kklabel/index.js";
import {getUser, searchNotes} from "../../utils/request";
import queryString from "query-string";
import {whOne300} from "../../utils/image";
import KKLables from "../../module/kklabel/labels";


const NoteList = (props) => {

    const [show, setShow] = useState(true)
    const [load, setLoad] = useState(false)

    const [search, setSearch] = useState("")
    const [searchLabel, setSearchLabel] = useState("")
    const [searchLabels, setSearchLabels] = useState([])

    const [user, setUser] = useState({
        image: undefined
    })

    const [notes, setNotes] = useState([])

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [pageTotal, setPageTotal] = useState(100)

    useEffect(() => {
        if (show) {
            setShow(false)
            let query = queryDate();
            if (query.search !== undefined && query.search.trim() !== '') {
                setSearch(query.search)
            }
            if (query.searchLabels !== undefined && query.searchLabels.trim() !== '') {
                setSearchLabels(query.searchLabels.split('|'))
            }
            if (query.page !== undefined && query.page.trim() !== '') {
                setPage(query.page)
            }
            if (query.pageSize !== undefined && query.pageSize.trim() !== '') {
                setPageSize(query.pageSize)
            }
            if (query.pageTotal !== undefined && query.pageTotal.trim() !== '') {
                setPageTotal(query.pageTotal)
            }
            requestNotes()
        }
    })

    const queryDate = () => {
        let da = {}
        if (props.location.search !== undefined && props.location.search.trim() !== '') {
            da = queryString.parse(props.location.search)
        }
        return da;
    }

    const requestNotes = () => {
        setLoad(true)
        getUser(props.match.params.userId).then(res => {
            setUser({
                userId: res.data.userId,
                image: res.data.photo,
                name: res.data.name,
                isAuthor: res.data.author,
            })
        }).catch(() => window.location.href = '/404')
        let query = queryDate();
        let searchLabels = []
        if (query.searchLabels !== undefined && query.searchLabels.length > 1) {
            searchLabels = query.searchLabels.split("|")
        }
        searchNotes({
            page: query.page,
            pageSize: query.pageSize,
            query: {
                userId: props.match.params.userId,
                searchContent: query.search,
                label: searchLabels,
            }
        }).then(res => {
            setPage(res.data.page)
            setPageSize(res.data.pageSize)
            setPageTotal(res.data.total)
            setNotes(res.data.items)
        }).finally(() => {
            setLoad(false)
        })
    }


    const searchLabelClick = () => {
        if (searchLabel !== undefined && searchLabel.trim() !== "") {
            let sl = searchLabels
            sl[searchLabels.length] = searchLabel
            setSearchLabels(sl)
            setSearchLabel("")
        }
    }

    const searchButton = () => {
        queryRequest(search, searchLabels, 1, pageSize)
    }

    const changePage = (page, pageSize) => {
        setPage(page)
        setPageSize(pageSize)
        queryRequest(search, searchLabels, page, pageSize)
    }

    const queryRequest = (search, searchLabels, page, pageSize) => {
        let query = queryDate();
        if (typeof (search) === "undefined" || search.trim() === '') {
            query.search = undefined
        } else {
            query.search = search
        }
        if (typeof (searchLabels) === "undefined" || searchLabels.length === 0) {
            query.searchLabels = undefined
        } else {
            query.searchLabels = searchLabels.join("|")
        }
        if (typeof (page) === "undefined" || page === 1) {
            query.page = undefined
        } else {
            query.page = page
        }
        if (typeof (pageSize) === "undefined" || pageSize === 10) {
            query.pageSize = undefined
        } else {
            query.pageSize = pageSize
        }
        let qs = queryString.stringify(query)
        window.location.search = qs
    }

    const openNote = (id) => {
        window.location.href = '/notes/' + id
    }

    const isPrivate = (isPrivate) => {
        if (isPrivate === 1) {
            return (<span className={noteListScss.private + ' private-span'}>私有</span>)
        }
        return (<i></i>)
    }

    const addNoteButton = (isAuthor) => {
        if (isAuthor !== null && isAuthor !== undefined && isAuthor) {
            return (
                    <Button className={'button-white-green'} type="primary" shape="round"
                            onClick={() => window.location.href = '/notes/save'}>
                        添加笔记
                    </Button>
            )
        }
        return (
            <Button className={'button-blue'} type="primary" shape="round"
                    onClick={() => window.location.href = '/login'}>
                登录
            </Button>
        )
     }

    const getNoteColWidth = () => {
        let w = 2
        for (var i = 0; i < document.getElementsByClassName("noteColWidth").length; i++) {
            let e = document.getElementsByClassName("noteColWidth")[i]
            if (e !== null && e !== undefined) {
                if (w < e.offsetWidth) {
                    w = e.offsetWidth
                }
            }
        }
        return w
    }

    const noteNode = (node, index) => {
        return (
            <Col key={index} span={24} className={noteListScss.noteCol + ' noteColWidth'} onClick={() => {
                openNote(node.id)
            }}>
                <Row className={noteListScss.dropdownDivider}></Row>
                <Row className={noteListScss.title}>
                    {node.title}
                    {isPrivate(node.isPrivate)}
                </Row>
                {KKLables(node.label,(name) => {}, getNoteColWidth(), noteListScss.label, noteListScss.labelSpace)}
                <Row>
                    <p className={noteListScss.markdown}>
                        {node.markdown}
                    </p>
                </Row>
            </Col>
        )
    }

    const nodeList = (colWight) => (
        <Col span={colWight}>
            <Row className={noteListScss.searchRow}>
                <Col span={20}>
                    <Input className={'input-green'} placeholder="搜索" value={search}
                           onChange={e => setSearch(e.target.value)} onPressEnter={searchButton}/>
                </Col>
                <Col span={4}>
                    <Button className={'button-green'} type="primary" htmlType="submit"
                            onClick={searchButton}>
                        搜索
                    </Button>
                </Col>
            </Row>
            <Row className={noteListScss.labelRow}>
                <Col span={12}>
                    <Space align="center" className={noteListScss.labelSpace}>
                        {searchLabels.map(label => (
                            <Tooltip key={label} title="点击删除" placement="bottom"
                                     overlayClassName={noteListScss.tooltip}>
                                {
                                    KKLabel(label, (name) => {
                                        let index = 0;
                                        let sl = []
                                        searchLabels.map((value) => {
                                            if (value !== name) {
                                                sl[index++] = value;
                                            }
                                        })
                                        setSearchLabels(sl)
                                    })
                                }
                            </Tooltip>
                        ))}
                    </Space>
                </Col>
                <Col span={8}>
                    <Input className={'input-green'} placeholder="添加搜索标签" value={searchLabel}
                           onChange={e => setSearchLabel(e.target.value)} onPressEnter={searchLabelClick}/>
                </Col>
                <Col span={4}>
                    <Button className={'button-green'} onClick={searchLabelClick} type="primary"
                            htmlType="submit">
                        添加
                    </Button>
                </Col>
            </Row>
            <Row>
                {notes.map((note, index) => (noteNode(note, index)))}
            </Row>
            <Row>
                <Col span={24} className={noteListScss.pageNormal}>
                    <Pagination className={noteListScss.pagination} current={page} pageSize={pageSize}
                                total={pageTotal} onChange={changePage} showSizeChanger={false}/>
                </Col>
                <Col span={24} className={noteListScss.pageSmall}>
                    <Pagination className={noteListScss.pagination} size="small" current={page}
                                pageSize={pageSize} total={pageTotal} onChange={changePage} showSizeChanger={false}/>
                </Col>
            </Row>
        </Col>
    );

    return (
        ordinaryPage(
            <Space align="start" className={noteListScss.spaceClass}>
                <Spin spinning={load || show}>
                    <div className={noteListScss.spinDiv}>
                        <div className={noteListScss.content}>
                            <Row className={noteListScss.noteListRow1}>
                                <Col span={6}>
                                    <Row>
                                        <Col span={24}>
                                            <Avatar className={noteListScss.imageCol} size={150} icon={<UserOutlined/>}
                                                    src={whOne300(user.image)}/>
                                        </Col>
                                        <Col span={24}>
                                            <h1>{user.name}</h1>
                                        </Col>
                                        <Col span={24}>
                                            {addNoteButton(user.isAuthor)}
                                        </Col>
                                    </Row>
                                </Col>
                                {nodeList(18)}
                            </Row>
                            <Row className={noteListScss.noteListRow2}>
                                {nodeList(24)}
                                <Col offset={22}>
                                    <Affix offsetBottom={30} className={noteListScss.affix}>
                                        <Button className={'button-white-green'} type="primary" shape="circle"
                                                icon={<PlusOutlined/>} size="large"
                                                onClick={() => window.location.href = '/notes/save'}/>
                                    </Affix>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Spin>
            </Space>
        )
    )
}


export default NoteList;