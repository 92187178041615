import {notification} from 'antd';

export function successTip(data) {
    openNotification({
        type: 'success',
        message: data.msg,
        description: data.desc,
    })
}

export function infoTip(data) {
    openNotification({
        type: 'info',
        message: data.msg,
        description: data.desc,
    })
}


export function warnTip(data) {
    openNotification({
        type: 'warning',
        message: data.msg,
        description: data.desc,
    })
}

export function errorTip(data) {
    openNotification({
        type: 'error',
        message: data.msg,
        description: data.desc,
    })
}

export function openNotification(data) {
    notification[data.type]({
        message: data.message,
        description: data.description,
    });
}
