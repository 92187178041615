import React, {useEffect, useState} from 'react';
import noteSaveScss from "./index.module.scss";
import ordinaryPage from "../../components/ordinaryPage";
import {Button, Col, Input, Radio, Row, Space, Spin} from 'antd';
import vditorDe from '../../module/vditor';
import {getMyNotes, saveNote} from "../../utils/request";
import {errorTip, successTip} from "../../utils/notification";
import KKLables from "../../module/kklabel/labels";

const NoteSave = (props) => {

    const [show, setShow] = useState(true)
    const [load, setLoad] = useState(false)

    const [title, setTitle] = useState("")
    const [label, setLabel] = useState("")
    const [labels, setLabels] = useState([])

    const [isPrivate, setIsPrivate] = useState(0)

    const [vditor, setVditor] = useState(undefined)

    let vd = undefined;

    const [data, setData] = useState({labels: []})

    useEffect(() => {
        if (show) {
            setShow(false)
            getNote()
        }
    })

    const getVditor = (val) => {
        setVditor(val)
        vd = val
        if (data.content !== undefined) {
            val.setValue(data.content, false)
        }
    }

    const checkWidth = (bigSize, smallSize) => {
        if (document.body.clientWidth > 767) {
            return bigSize
        } else {
            return smallSize
        }
    }

    const getNote = () => {
        setLoad(true)
        let noteId = props.match.params.noteId;
        if (noteId !== undefined) {
            getMyNotes(props.match.params.noteId)
                .then(res => {
                    setData(res.data)
                    setIsPrivate(res.data.isPrivate)
                    setTitle(res.data.title)
                    setLabels(res.data.labels)
                    if (vd !== undefined) {
                        const intervalID = setInterval(() => {
                            if (document.getElementById(vd.vditor.element.id) !== undefined
                                && document.getElementById(vd.vditor.element.id).className === 'vditor') {
                                vd.setValue(res.data.content, false)
                                clearInterval(intervalID)
                            }
                        }, 10)
                    }
                })
                .catch(e => {
                    setTimeout(() => window.location.href = '/404', 1500)
                })
                .finally(() => {
                    setLoad(false)
                })
        } else {
            setLoad(false)
        }
    }

    const getLabelColWidth = () => {
        let w = 2
        for (var i = 0; i < document.getElementsByClassName("labelCol").length; i++) {
            let e = document.getElementsByClassName("labelCol")[i]
            if (e !== null && e !== undefined) {
                if (w < e.offsetWidth) {
                    w = e.offsetWidth
                }
            }
        }
        return w
    }

    const labelShow = (width, offset) => (
        <Col span={width} offset={offset} className={"labelCol"}>
            {KKLables(labels, (name) => {
                let index = 0;
                let sl = []
                data.labels.map((value) => {
                    if (value !== name) {
                        sl[index++] = value;
                    }
                })
                data.labels = sl
                setLabels(sl)
                setData(data)
            }, getLabelColWidth(), undefined, noteSaveScss.labelSpace)}
        </Col>
    );

    const clickLabel = () => {
        if (label.trim() !== "") {
            data.labels[data.labels.length] = label
            setData(data)
            setLabels(data.labels)
            setLabel("")
        }
    }

    const save = () => {
        let id = props.match.params.noteId;
        let content;
        if (typeof (title) === "undefined" || title.trim() === '') {
            errorTip({msg: '标题不能为空'})
            return
        }
        if (typeof (data.labels) === "undefined" || data.labels.length === 0) {
            errorTip({msg: '标签不能为空'})
            return
        }
        if (typeof (isPrivate) === "undefined" || (isPrivate !== 0 && isPrivate !== 1)) {
            errorTip({msg: '私有选项不能为空'})
            return
        }
        if (typeof (vditor) !== "undefined") {
            content = vditor.getValue()
        }
        if (typeof (content) === "undefined" || content.trim() === '') {
            errorTip({msg: '内容不能为空'})
            return
        }
        let da = {
            id: id,
            title: title,
            label: data.labels.join("|"),
            markdown: content,
            isPrivate: isPrivate
        }
        saveNote(da).then(res => {
            if (typeof (id) === "undefined") {
                window.location.href = '/notes/save/' + res.data.id
            }
            successTip({msg: '保存成功'})
        })
    }

    const showNote = () => {
        window.location.href = '/notes/' + props.match.params.noteId
    }


    const labelInput = (width) => (
        <Col span={width}>
            <Row>
                <Col span={16}>
                    <Input className={'input-green'} placeholder="添加搜索标签"
                           value={label} onChange={e => setLabel(e.target.value)} onPressEnter={clickLabel}/>
                </Col>
                <Col span={8}>
                    <Button className={"button-green " + noteSaveScss.addButton} type="primary"
                            htmlType="submit" onClick={clickLabel}>
                        添加
                    </Button>
                </Col>
            </Row>
        </Col>
    );

    const viewButton = () => {
        if (props.match.params.noteId !== undefined) {
            return (
                <Button className={noteSaveScss.viewButton + ' button-blue'} onClick={() => showNote()}>查看笔记</Button>
            )
        }
        return (undefined)
    }

    return ordinaryPage(
        <Space align="start" className={noteSaveScss.spaceClass}>
            <Spin spinning={load || show}>
                <div className={noteSaveScss.spinDiv}>
                    <div className={noteSaveScss.content}>
                        <Row justify="center" className={noteSaveScss.row}>
                            <Col span={24} className={noteSaveScss.title}>
                                <h1>笔记</h1>
                            </Col>
                        </Row>
                        <Row className={noteSaveScss.row} justify="center" align="middle">
                            <Col span={4} offset={2}>
                                标题：
                            </Col>
                            <Col span={16}>
                                <Input className={'input-green'} placeholder="标题" value={title}
                                       onChange={e => {
                                           setTitle(e.target.value)
                                           setData(data)
                                       }}/>
                            </Col>
                        </Row>
                        <Row className={noteSaveScss.labelRowMedium} justify="center" align="middle">
                            <Col span={4} offset={2}>
                                标签：
                            </Col>
                            {labelShow(10, 0)}
                            {labelInput(6)}
                        </Row>
                        <Row className={noteSaveScss.labelRowSmall} justify="center" align="middle">
                            <Col span={4} offset={2}>
                                标签：
                            </Col>
                            {labelInput(16)}
                        </Row>
                        <Row className={noteSaveScss.labelRowSmall} justify="center" align="middle">
                            {labelShow(16, 6)}
                        </Row>
                        <Row className={noteSaveScss.isPrivateRow + ' ant-radio-green'} justify="center" align="middle">
                            <Col span={4} offset={2}>
                                私有：
                            </Col>
                            <Col span={16} className={noteSaveScss.colIsPrivate}>
                                <Radio.Group onChange={(e) => {
                                    data.isPrivate = e.target.value
                                    setIsPrivate(e.target.value)
                                    setData(data)
                                }} value={isPrivate} defaultValue={0}>
                                    <Radio value={0}>公开</Radio>
                                    <Radio value={1}>私有</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row className={noteSaveScss.row + ' ' + noteSaveScss.vditor} justify="center" align="middle">
                            <Col span={checkWidth(20, 24)} offset={checkWidth(2, 0)}>
                                {vditorDe(getVditor)}
                            </Col>
                        </Row>
                        <Row className={noteSaveScss.row + ' ' + noteSaveScss.buttonRow}>
                            <Col span={16} offset={4}>
                                <Button className={'button-white-green'} onClick={() => save()}>保存笔记</Button>
                                {viewButton()}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Space>
        , true
        , true
    )
}

export default NoteSave;
