import React from 'react';
import indexScss from './index.module.scss'
import ordinaryPage from '../../components/ordinaryPage/';
import logo from '../../images/kknotes.png';
import { Space,Image } from 'antd';


const Index = () => (
    ordinaryPage(
        <Space className={indexScss.spaceClass} align="center">
            <Image className={indexScss.imageClass} preview={false} src={logo} />
        </Space>
    )
);



export default Index;