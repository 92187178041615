import React, {useEffect, useState} from 'react';
import {Avatar, Col, Dropdown, Menu, Modal, Row, Space, Spin, Tooltip, Typography} from 'antd';
import {ExclamationCircleOutlined, UserOutlined} from '@ant-design/icons';
import ordinaryPage from "../../components/ordinaryPage";
import noteScss from "./index.module.scss";
// import vditorDe from '../../module/vditor';
// import Vditor from 'vditor';
import Preview from '../../module/vditor/preview';
import {deleteNote, getNotes} from "../../utils/request";
import {successTip} from "../../utils/notification";
import moment from "moment";
import {whOne100} from "../../utils/image";
import KKLables from "../../module/kklabel/labels";


const {Title} = Typography;
const {confirm} = Modal;

const Node = (props) => {

    const [show, setShow] = useState(true)
    const [load, setLoad] = useState(false)

    const [data, setData] = useState({labels: []})

    const [auther, setAuther] = useState({photo: undefined})

    const [vditor, setVditor] = useState({})
    const [html, setHtml] = useState("")

    useEffect(() => {
        if (show) {
            setShow(false)
            requestNotes()
        }
    })

    const requestNotes = () => {
        setLoad(true)
        getNotes(props.match.params.noteId)
            .then(res => {
                setData(res.data)
                setAuther(res.data.auther)
                Preview("preview", "outline", res.data.content)
            })
            .catch(e => {
                setTimeout(() => window.location.href = '/404', 1500)
            })
            .finally(() => {
                setTimeout(() => setLoad(false), 300)
            })
    }

    const menuDelete = (
        <Menu onClick={(e) => {
            if (e.key === '1') {
                confirm({
                    title: '删除笔记后,无法恢复',
                    icon: <ExclamationCircleOutlined/>,
                    okText: '删除',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        deleteNote(props.match.params.noteId).then(res => {
                            successTip({msg: "删除成功"})
                            document.getElementsByClassName('user-name')[0].click()
                        })
                    },
                    onCancel() {
                    },
                });
            }
        }}>
            <Menu.Item key="1">删除笔记</Menu.Item>
        </Menu>
    );


    const getVditor = (vditor) => {
        setVditor(vditor)
    }

    const formatDate = (date) => {
        if (document.body.clientWidth > 767) {
            return (moment(parseInt(date)).format('YYYY-MM-DD HH:mm:ss'))
        } else {
            return (moment(parseInt(date)).format('YYYY-MM-DD'))
        }
    }

    const isPrivate = (isPrivate) => {
        if (isPrivate === 1) {
            return (<span className={noteScss.private + ' private-span'}>私有</span>)
        }
        return (<i></i>)
    }

    const updateButton = () => {
        if (data.isAuthor === true) {
            return (<Dropdown.Button overlay={menuDelete} onClick={(e) => {
                window.location.href = '/notes/save/' + props.match.params.noteId
            }} className={'dropdown-button-color-primary-green'}
            >修改笔记</Dropdown.Button>)
        }
        return (<i></i>)
    }

    const getTitleWidth = () =>{
        let e = document.getElementById("titleId")
        if(e !== null){
            return e.offsetWidth;
        }
        return 2
    }

    return ordinaryPage(
        <Space align="start" className={noteScss.spaceClass}>
            <Spin spinning={load || show}>
                <div className={noteScss.spinDiv}>
                    <div className={noteScss.content}>
                        <Row className={noteScss.row}>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 16, offset: 4}}>
                                <Title id = "titleId" level={document.body.clientWidth > 767 ? 2 : 3}>
                                    {data.title}
                                    {isPrivate(data.isPrivate)}
                                </Title>
                            </Col>
                        </Row>
                        <Row className={noteScss.row + ' ' + noteScss.rowAuther} align="middle">
                            <Col xs={{span: 15, offset: 1}} sm={{span: 15, offset: 1}} md={{span: 12, offset: 4}}>
                                <Row align="middle">
                                    <Col>
                                        <Avatar className={noteScss.imageCol} size={50} icon={<UserOutlined/>}
                                                src={whOne100(auther.photo)}/>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col span={24} className={noteScss.autherName}>
                                                <a className={noteScss.autherNameA} onClick={() => {
                                                    window.open('/user/' + auther.userId)
                                                }}>{auther.name}</a>
                                            </Col>
                                            <Col span={24} className={noteScss.createDate}>
                                                <Tooltip placement="bottom"
                                                         title={'最后编辑时间：' + formatDate(data.updateDate)}>
                                                    <span
                                                        className={noteScss.timeSpan}>{formatDate(data.createDate)}</span>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={4} className={noteScss.noteButton}>
                                {updateButton()}
                            </Col>
                        </Row>
                        {KKLables(data.labels,(name) => {},getTitleWidth(), noteScss.rowLabel, undefined, {span: 23, offset: 1},
                            {span: 23,offset: 1}, {span: 16, offset: 4})}
                        <Row className={noteScss.row + ' ' + noteScss.html}>
                            <Col xs={{span: 23, offset: 1}} sm={{span: 23, offset: 1}} md={{span: 16, offset: 4}}>
                                <div id="outline"></div>
                                <div id="preview"></div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Space>
    )

}

export default Node;

