import fetch from 'cross-fetch';
import {errorTip} from './notification';

const api = '/api/';

const get = data => ({
    method: 'GET',
})

export function getRequest(uri, data, loginTic) {
    return request(uri, data, get, loginTic)
}

const postApplicationJson = body => ({
    method: 'POST',
    dataType: 'json',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(body)
})


export function postApplicationJsonRequest(uri, data, loginTic) {
    return request(uri, data, postApplicationJson, loginTic)
}

const postForm = body => ({
    method: 'POST',
    dataType: 'json',
    // headers: new Headers({Token:body.token}),
    headers: {
        Token: body.token,
        // 'Content-Type': 'multipart/form-data',
// 'Access-Control-Allow-Headers': 'X-File-Name, X-File-Type, X-File-Size',
// 'Access-Control-Allow-Methods': 'OPTIONS, HEAD, POST',
// 'Access-Control-Allow-Origin': '*',
// 'Access-Control-Expose-Headers': 'X-Log, X-Reqid',
// 'Access-Control-Max-Age': '2592000',
    },
    body: body.form,
})

export function postFormRequest(uri, data, loginTic) {
    return request(uri, data, postForm, loginTic)
}

export function request(uri, data, type, loginTic) {
    if (typeof (data) === "undefined") {
        data = {}
    }
    let url = api + uri;
    if (uri.startsWith('http') || uri.startsWith('//')) {
        url = uri;
    }
    return new Promise((resolve, reject) => {
        return fetch(url, type(data))
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    errorTip({
                        msg: '服务异常'
                    })
                    return new Promise((resolve, reject) => {
                        return {'code': "-100"}
                    })
                }
            })
            .then(res => {
                if (res.code === "0") {
                    resolve(res)
                } else if (res.code === "3" || res.code === "0x030103") {
                    if (loginTic !== undefined && loginTic === true) {
                        errorTip({
                            msg: res.msg
                        })
                    }
                    reject({type: 'then', res: res})
                } else if (res.code === "-100") {
                    reject({type: 'then', res: res})
                } else {
                    errorTip({
                        msg: res.msg
                    })
                    reject({type: 'then', res: res})
                }
            })
            .catch(e => {
                errorTip({
                    msg: e,
                })
                reject({type: 'catch', e: e})
            })
    })
}


/**
 * 发送验证码
 * @param data
 * @returns {Promise<unknown>}
 */
export function sendCaptcha(data) {
    return postApplicationJsonRequest('sendCaptcha', {
        phone: data.phone,
        randstr: data.randstr,
        ticket: data.ticket,
    });
}

/**
 * 用户登录
 * @param data
 * @returns {Promise<unknown>}
 */
export function login(data) {
    return postApplicationJsonRequest('login', {
        phone: data.phone,
        captcha: data.captcha
    })
}


/**
 * 获取用户信息
 * @param data
 * @returns {Promise<unknown>}
 */
export function getUser(userId, loginTic) {
    let uri = 'user'
    if (userId !== undefined) {
        uri += '/' + userId
    }
    return postApplicationJsonRequest(uri, {}, loginTic)
}

/**
 * 搜索笔记
 * @param data
 * @returns {Promise<unknown>}
 */
export function searchNotes(data) {
    return postApplicationJsonRequest('notes/search', data)
}

/**
 * 获取文章信息
 * @param id
 * @returns {Promise<unknown>}
 */
export function getNotes(id) {
    return postApplicationJsonRequest('notes/' + id, {}, false)
}

/**
 * 获取自己的文章信息
 * @param id
 * @returns {Promise<unknown>}
 */
export function getMyNotes(id) {
    return postApplicationJsonRequest('notes/my/' + id, {}, false)
}

/**
 * 保存笔记
 * @param data
 * @returns {Promise<unknown>}
 */
export function saveNote(data) {
    return postApplicationJsonRequest('notes/save', {
        id: data.id,
        title: data.title,
        label: data.label,
        markdown: data.markdown,
        isPrivate: data.isPrivate,
    })
}

/**
 * 删除笔记
 * @param id
 * @returns {Promise<unknown>}
 */
export function deleteNote(id) {
    return postApplicationJsonRequest('notes/delete/' + id)
}

/**
 * 获取 用户设置信息
 * @returns {Promise<unknown>}
 */
export function getSetting() {
    return postApplicationJsonRequest('user/getSetting')
}

/**
 * 保存 用户设置信息
 * @param data
 * @returns {Promise<unknown>}
 */
export function saveSetting(data) {
    return postApplicationJsonRequest('user/setting', {
        name: data.name,
        photo: data.photo
    })
}

/**
 * 获取 七牛云上传token
 * @returns {Promise<unknown>}
 */
export function qiniuToken() {
    return postApplicationJsonRequest('qiniu/getToken')
}

/**
 * 七牛云 上传图片
 * @param data
 * @returns {Promise<unknown>}
 */
export function uploadQiniu(data) {
    let url = '//upload-z2.qiniup.com/'

    const postForm = body => ({
        method: 'POST',
        dataType: 'json',
        headers: {
            Token: body.token,
        },
        body: body.form,
    })

    return new Promise((resolve, reject) => {
        return fetch(url, postForm(data))
            .then(res => res.json())
            .then(res => {
                if (res.success === "1") {
                    resolve(res)
                } else {
                    errorTip({
                        msg: res.message
                    })
                    reject({type: 'then', res: res})
                }
            })
            .catch(e => {
                console.log(e)
                errorTip({
                    msg: e,
                })
                reject({type: 'catch', e: e})
            })
    })
}
