import React, {useEffect, useState} from 'react';
import loginScss from './index.module.scss';
import ordinaryPage from '../../components/ordinaryPage/';
import {Button, Col, Image, Input, Row, Space} from 'antd';
import logo from "../../images/kknotes.png";
import {successTip, warnTip} from '../../utils/notification';
import {login, sendCaptcha} from '../../utils/request'

const Login = () => {

    const [show, setShow] = useState(true)
    const [load, setLoad] = useState(false)

    const [phone, setPhone] = useState("")
    const [vCode, setVCode] = useState("")

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (show) {
            setShow(false)
            setLoad(true)

            let tCaptcha = document.createElement('script')
            tCaptcha.src = 'https://ssl.captcha.qq.com/TCaptcha.js'

            let head = document.getElementsByTagName('head')[0]
            head.appendChild(tCaptcha)

            let script2 = document.createElement('script')
            script2.text = `
                window.captcha = function (res) {
                    window.requestVCode(res)
                };
              `
            document.body.appendChild(script2)

            window.requestVCode = requestVCode

            setLoad(false)
        }
    })

    const getVCode = () => {
        if (typeof (phone) === "undefined"  || phone.trim().length === 0) {
            warnTip({msg: '手机号码不能为空'})
            return
        }
        if (phone.trim().length !== 11 || !phone.startsWith('1')) {
            warnTip({msg: '手机号码格式异常'})
            return
        }
        document.getElementById('TencentCaptcha').click()
    }

    const requestVCode = (res) => {
        if (res.ret === 0) {
            let phone = document.getElementsByClassName('phoneInput')[0].value
            sendCaptcha({
                phone: phone,
                randstr: res.randstr,
                ticket: res.ticket,
            }).then(res => {
                setLoading(true)
                successTip({msg: '验证码已发送,请注意查收'})
                setTimeout(() => setLoading(false), 60000)
            })
            return
        }
    }

    const userLogin = () => {
        if (typeof (phone) === "undefined"|| phone.trim().length === 0) {
            warnTip({msg: '手机号码不能为空'})
            return
        }
        if (phone.trim().length !== 11 || !phone.startsWith('1')) {
            warnTip({msg: '手机号码格式异常'})
            return
        }
        if (typeof (vCode) === "undefined"|| vCode.trim().length === 0) {
            warnTip({msg: '验证码不能为空'})
            return
        }
        login({
            phone: phone,
            captcha: vCode
        }).then(res => {
            console.log(res)
            window.location.href = '/user/' + res.data.id
            //
            // this.props.history.push({
            //     pathname : '/user/'+res.data.id
            // });
        })
    }

    const loginHtml = (spanWight) => (
        <Col span={spanWight}>
            <Row className={loginScss.rowClass}>
                <Col span={24}>
                    <h4 className={loginScss.spanName}>登录</h4>
                </Col>
            </Row>
            <Row className={loginScss.rowClass}>
                <Col span={16} offset={4} className={loginScss.phoneCol}>
                    <Input className='phoneInput' placeholder="您的手机号" value={phone}
                           onChange={e => setPhone(e.target.value)}/>
                </Col>
            </Row>
            <Row className={loginScss.rowClass}>
                <Col span={10} offset={4} className={loginScss.codeCol}>
                    <Input placeholder="验证码" value={vCode} onChange={e => setVCode(e.target.value)}/>
                </Col>
                <Col span={6} className={loginScss.vCodeCol}>
                    <Button type="primary" htmlType="submit"
                            loading={loading}
                            onClick={() => getVCode()}>
                        {loading ? '等待验证' : '获取验证码'}
                    </Button>
                </Col>
            </Row>
            <Row className={loginScss.rowClass}>
                <Col span={16} offset={4} className={loginScss.loginButtonCol}>
                    <Button type="primary" htmlType="submit" onClick={() => userLogin()}>
                        登录/注册
                    </Button>
                </Col>
            </Row>

        </Col>
    )

    return (
        ordinaryPage(
            <Space align="center" className={loginScss.spaceClass}>
                <Row className={loginScss.loginRow1}>
                    <Col span={12}>
                        <Image preview={false} src={logo}/>
                    </Col>
                    {loginHtml(12)}
                </Row>
                <Row className={loginScss.loginRow2}>
                    {loginHtml(24)}
                </Row>
                <Row className={loginScss.tencentCaptchaRow}>
                    <button id="TencentCaptcha"
                            data-appid="2053990048"
                            data-cbfn="captcha"
                            type="submit"
                    >

                    </button>
                </Row>
            </Space>
        )
    )
}

export default Login;