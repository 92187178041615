import React, {useEffect} from 'react';
import Vditor from 'vditor';
import Options from './options';
import './index.scss'
import {qiniuToken, uploadQiniu} from "../../utils/request";
import {whTwo1200} from "../../utils/image";


let first = true;

const VditorDe = (getVditor) => {
    let vditor;

    const updatePhoto = (files) => {
        clearInputFile()
        for (let key in files) {
            qiniuToken().then(res => {
                let formData = new FormData();
                formData.append('token', res.data.token)
                formData.append('file', files[key])
                uploadQiniu({
                    token: res.data.token,
                    form: formData
                }).then(res => {
                    console.log(res)
                    vditor.insertValue('![](http:' + whTwo1200(res.url) + ')', true)
                }).catch(e => {
                    console.log(e)
                    throw e
                })
            }).catch(e => {
                console.log(e)
                throw e
            })
        }
    }

    const clearInputFile = () => {
        let inputs = document.getElementsByTagName('input');
        if (typeof (inputs) === "undefined") {
            return
        }
        for (let index in inputs) {
            if (inputs[index].type === 'file') {
                inputs[index].value = ''
            }
        }
    }

    useEffect(() => {
        if (first) {
            first = false;
            vditor = new Vditor('vditor_3DWjXgMSfSuf30i2', Options(document.body.clientWidth, updatePhoto))
            getVditor(vditor);
        }
    })

    return (
        <div id='vditor_3DWjXgMSfSuf30i2'></div>
    )
}

export default VditorDe;
