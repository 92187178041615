import React from 'react';
import lableScss from './index.module.scss';


let colorLabels = [
    // "#2259aa", "#dbec4c", "#91ecd3", "#cb4ef8"
    // , "#8359b6", "#736970", "#c9afe6", "#73e941"
    // , "#b8554c", "#87ebbb", "#2de233", "#18f472"
    // , "#118c67", "#95770e", "#aa7d3f", "#a88ca0"
    // , "#a201d3", "#f5c11d", "#d9d6ab", "#446746"
    // , "#8a270f", "#3c80a9", "#35b229", "#eb4c79"
    // , "#acdca9", "#0fa863", "#b2b3b3", "#6a5f39"
    // , "#69f69e", "#9e1f48", "#2f97d0", "#5b2139"
    // , "#ab0e06", "#72811a", "#1b7240", "#652848"
    // , "#7b1f4e", "#1148c4", "#b41001", "#10ed36"
    // , "#ac3a63", "#debc71", "#e26665", "#0114e1"
    // , "#451ac3", "#65634b", "#093af5", "#680d4c"
    // , "#77f48c", "#dcb5bc", "#138a63", "#fa1870"
    // , "#c8579e", "#8a6df5", "#48e06b", "#d44dd7"
    // , "#6e0d60", "#f1d462", "#c97240", "#6eb3f3"
    // , "#c9a44c", "#0990b1", "#11cd6a", "#a3e192"
    // , "#827019", "#b349ea", "#4f17c2", "#f4536b"
    // , "#f3cf89", "#d0579a", "#9e92c1", "#193501"
    // , "#9c7b2f", "#dcc476", "#19ec01", "#ca84d5"
    // , "#5dd3da", "#788593", "#f80dd0", "#1db8f7"
    // , "#f35e56", "#38bd0c", "#4dea9e", "#9d29a1"
    // , "#612b2c", "#3fd470", "#0c7f44", "#3328e3"
    // , "#02b3b5", "#cb7cf9", "#6add71", "#622eec"
    // , "#8cf385", "#38378f", "#3fb0e2", "#926b60"
    // , "#861ba7", "#9d0d6c", "#be247f", "#76bc5a"
    // , "#c61b5f", "#85aea8", "#0a5127", "#f5ca6b"
    // , "#8a7a17", "#f0de33", "#4493fc", "#36e323"
    // , "#2c3ca2", "#68cf7c", "#44b886", "#d0fb74"
    // , "#014c8b", "#736e8a", "#3e3480", "#eac740"
    // , "#6e4d10", "#5d6bc3", "#b68422", "#c4cf67"
    // , "#8b9a05", "#2163ef", "#e26981", "#5f29d1"
    // , "#45a551", "#dffb4d", "#9fbbf6", "#cbb074"
    // , "#96c416", "#3e93d4", "#d5cac2", "#da47cd"
    // , "#4e5611", "#b30d56", "#fb1570", "#6de67c"
    // , "#3d60b7", "#88b823", "#3c0825", "#f995e4"
    // , "#7605fa", "#5adc7c", "#4bea81", "#09bf8c"
    // , "#08ee75", "#c126c0", "#8b0ceb", "#be1928"
    // , "#89fd01", "#526e21", "#27f990", "#c7a9d6"
    // , "#26debb", "#77c853", "#6afa0e", "#844762"
    // , "#d9a58b", "#a59be3", "#fa8139", "#d7d87b"
    // , "#fb31b6", "#d06fbe", "#b57367", "#f30331"
    // , "#78729b", "#da684b", "#cada39", "#5a6f8c"
    // , "#cdab99", "#7fb546", "#173797", "#937abf"
    // , "#28fe25", "#98e55b", "#ff06e4", "#677fc7"
    // , "#c766cb", "#b5d2d8", "#ec33e5", "#177948"
    // , "#c999e2", "#cafdcd", "#cc7059", "#c1be93"
    // , "#186193", "#fa240e", "#ace9be", "#1ad4fa"
    // , "#53ce29", "#1225c0", "#0415a1"];


"#FFB6C1",
"#FFC0CB",
"#DC143C",
// "#FFF0F5",
"#DB7093",
"#FF69B4",
"#FF1493",
"#C71585",
"#DA70D6",
// "#D8BFD8",
"#DDA0DD",
"#EE82EE",
"#FF00FF",
"#FF00FF",
"#8B008B",
"#800080",
"#BA55D3",
"#9400D3",
"#9932CC",
// "#4B0082",
"#8A2BE2",
"#9370DB",
"#7B68EE",
"#6A5ACD",
// "#483D8B",
// "#E6E6FA",
// "#F8F8FF",
"#0000FF",
// "#0000CD",
// "#191970",
// "#00008B",
// "#000080",
"#4169E1",
"#6495ED",
// "#B0C4DE",
"#778899",
"#708090",
"#1E90FF",
// "#F0F8FF",
"#4682B4",
"#87CEFA",
"#87CEEB",
"#00BFFF",
// "#ADD8E6",
"#B0E0E6",
"#5F9EA0",
// "#F0FFFF",
// "#E1FFFF",
"#AFEEEE",
// "#00FFFF",
// "#D4F2E7",
"#00CED1",
// "#2F4F4F",
"#008B8B",
"#008080",
"#48D1CC",
"#20B2AA",
"#40E0D0",
"#7FFFAA",
// "#00FA9A",
// "#00FF7F",
// "#F5FFFA",
"#3CB371",
"#2E8B57",
// "#F0FFF0",
"#90EE90",
"#98FB98",
"#8FBC8F",
"#32CD32",
// "#00FF00",
"#228B22",
"#008000",
"#006400",
// "#7FFF00",
// "#7CFC00",
// "#ADFF2F",
"#556B2F",
// "#F5F5DC",
// "#FAFAD2",
// "#FFFFF0",
// "#FFFFE0",
// "#FFFF00",
// "#808000",
"#BDB76B",
// "#FFFACD",
"#EEE8AA",
"#F0E68C",
"#FFD700",
// "#FFF8DC",
"#DAA520",
// "#FFFAF0",
// "#FDF5E6",
"#F5DEB3",
"#FFE4B5",
"#FFA500",
// "#FFEFD5",
// "#FFEBCD",
// "#FFDEAD",
// "#FAEBD7",
"#D2B48C",
"#DEB887",
// "#FFE4C4",
"#FF8C00",
// "#FAF0E6",
"#CD853F",
"#FFDAB9",
"#F4A460",
"#D2691E",
"#8B4513",
// "#FFF5EE",
"#A0522D",
"#FFA07A",
"#FF7F50",
"#FF4500",
"#E9967A",
"#FF6347",
// "#FFE4E1",
"#FA8072",
// "#FFFAFA",
"#F08080",
"#BC8F8F",
"#CD5C5C",
"#FF0000",
"#A52A2A",
"#B22222",
"#8B0000",
"#800000"];

/**
 * 颜色转换 十六进制 转 rgb
 * @param hex
 * @returns {Array}
 */
const hexToRgb = (hex) => {
    let rgb = [];
    hex = hex.substr(1);//去除前缀 # 号
    if (hex.length === 3) { // 处理 "#abc" 成 "#aabbcc"
        hex = hex.replace(/(.)/g, '$1$1');
    }
    hex.replace(/../g, function (color) {
        rgb.push(parseInt(color, 0x10));//按16进制将字符串转换为数字
    });
    return rgb;
}

/**
 * 判断浅色和深色
 * @param rgb
 * return ture 浅色 / false 深色
 */
const depth = (rgb) => {
    return rgb[0] * 0.299 + rgb[1] * 0.578 + rgb[2] * 0.114 >= 192;
}

/**
 * 获取颜色
 * @param str
 */
const getBackgroundColor = (str) => {
    let array = (str + "").split('');
    let num = 0;
    array.forEach(function (value) {
        num += value.charCodeAt();
    });
    return colorLabels[num % colorLabels.length];
}

const getColor = (str) => {
    if (depth(hexToRgb(getBackgroundColor(str)))) {
        return "#212529";
    } else {
        return "#fff";
    }
}

const KKLable = (name, click) => {
    return (
        <label key={name} className={lableScss.lableClass}
               style={{color: getColor(name), backgroundColor: getBackgroundColor(name)}}
               onClick={()=>{click(name)}}
        >{name}</label>
    )
}

export default KKLable;