import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routers from './components/router/'
import reportWebVitals from './reportWebVitals';


import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

const App =
    <BrowserRouter>
        <Switch>
            {Routers.map((item, index) => {
                return <Route key={index} path={item.path} component={item.component}/>
            })}
            <Redirect from="*" to="/"/>
        </Switch>
    </BrowserRouter>
;

ReactDOM.render(
    App,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
