import Index from '../../pages/index/';
import Login from '../../pages/login/';
import noteList from "../../pages/noteList";
import noteSave from "../../pages/noteSave";
import note from "../../pages/note";
import setting from "../../pages/setting";
import Error404 from "../../pages/error/error404";

const Routers = [
    {path: '/login', component: Login},
    {path: '/user/:userId', component: noteList},
    {path: '/notes/save/:noteId', component: noteSave},
    {path: '/notes/save', component: noteSave},
    {path: '/notes/:noteId', component: note},
    {path: '/setting', component: setting},
    {path: '/404', component: Error404},
    {path: '/', component: Index},
]

export default Routers;