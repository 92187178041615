import React, {useEffect, useState} from 'react';
import ordinaryPage from "../../components/ordinaryPage";
import {getSetting, qiniuToken, saveSetting, uploadQiniu} from "../../utils/request";
import settingScss from "./index.module.scss";
import {Button, Col, Input, Row, Space, Spin, Upload} from 'antd';
import {errorTip, successTip} from "../../utils/notification";
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {whOne200} from "../../utils/image";


const Setting = (props) => {

    const [show, setShow] = useState(true)
    const [load, setLoad] = useState(false)

    const [name, setName] = useState("")
    const [photo, setPhoto] = useState("")
    const [photoHash, setPhotoHash] = useState("")

    const [updateLoading, setUpdateLoading] = useState(false)

    useEffect(() => {
        if (show) {
            setShow(false)
            getSettingInfo()
        }
    })

    const getSettingInfo = () => {
        setLoad(true)
        getSetting().then(res => {
            setName(res.data.userSetting.name)
            setPhoto(res.data.imageHost + res.data.userSetting.photo)
            setPhotoHash(res.data.userSetting.photo)
        }).finally(() => {
            setLoad(false)
        })
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            errorTip({msg: '只能上传 JPG/PNG 格式图片'})
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            errorTip({msg: '上传图片不能大于2MB'})
        }
        return isJpgOrPng && isLt2M;
    }

    const updateHandleChange = info => {
        if (info.file.status === 'uploading') {
            setUpdateLoading(true)
            return;
        }
        if (info.file.status === 'done') {
        }
    };

    const updateImage = (img) => {
        return new Promise((resolve, reject) => {
            qiniuToken().then(res => {
                let formData = new FormData();
                formData.append('token', res.data.token)
                formData.append('file', img)
                uploadQiniu({
                    token: res.data.token,
                    form: formData
                }).then(res => {
                    setPhoto(res.url)
                    setPhotoHash(res.hash)
                    setUpdateLoading(false)
                    resolve(res.url)
                }).catch(e => {
                    setUpdateLoading(false)
                    reject(e)
                })
            }).catch(e => {
                setUpdateLoading(false)
                reject(e)
            })
        })
    }

    const uploadButton = (
        <div>
            {updateLoading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    const save = () => {
        saveSetting({
            name: name,
            photo: photoHash
        }).then(res => {
            successTip({msg: '保存成功'})
            setTimeout(() => window.location.reload(), 1500)
        })
    }

    return ordinaryPage(
        <Space align="start" className={settingScss.spaceClass}>
            <Spin spinning={load || show}>
                <div className={settingScss.spinDiv}>
                    <div className={settingScss.content}>
                        <Row justify="center" className={settingScss.row}>
                            <Col span={24} className={settingScss.title}>
                                <h1>用户设置</h1>
                            </Col>
                        </Row>
                        <Row className={settingScss.row} justify="center" align="middle">
                            <Col xs={{span: 4}} sm={{span: 4}} md={{span: 4, offset: 2}}>
                                名称：
                            </Col>
                            <Col xs={{span: 18}} sm={{span: 18}} md={{span: 12}}>
                                <Input className={'input-green'} placeholder="用户名称" value={name}
                                       onChange={e => setName(e.target.value)}/>
                            </Col>
                            <Col xs={{span: 0}} sm={{span: 0}} md={{span: 4}}></Col>
                        </Row>
                        <Row className={settingScss.row} justify="center" align="middle">
                            <Col xs={{span: 4}} sm={{span: 4}} md={{span: 4, offset: 2}}>
                                头像：
                            </Col>
                            <Col xs={{span: 18}} sm={{span: 18}} md={{span: 12}}>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={updateImage}
                                    beforeUpload={beforeUpload}
                                    onChange={updateHandleChange}
                                >
                                    {photo ? <img src={whOne200(photo)} alt="avatar"
                                                  style={{width: '100%'}}/> : uploadButton}
                                </Upload>
                            </Col>
                            <Col xs={{span: 0}} sm={{span: 0}} md={{span: 4}}></Col>
                        </Row>
                        <Row className={settingScss.row} justify="center" align="middle">
                            <Col span={24}>
                                <Button className={'button-white-green'} onClick={save}>保存设置</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Space>
        , true
        , true
    )

}


export default Setting