import {Col, Row, Space} from 'antd';
import KKLable from './index'


const KKLables = (label, click, width, labelClass, labelSpaceClass, xs, sm, md) => {

    const labelChild = (label) => {
        let htmIndex = 0;
        let htm = [];

        for (let index in label) {
            htm[htmIndex++] = KKLable(label[index], click)
        }
        return (htm.map(h => h))
    }

    const labels = () => {
        let htmlIndex = 0;
        let html = [];
        let i = 0;
        let j = 0;
        let labels = []
        for (let index in label) {
            if (labels[i] !== undefined && labels[i].length > 0) {
                let lo = 0
                for (let il in labels[i]) {
                    lo += (labels[i][il].replace(/[\u0391-\uFFE5]/g, "aa").length * 8) + 8.4 + 8.4 + 8
                }
                lo += (label[index].replace(/[\u0391-\uFFE5]/g, "aa").length * 8) + 17 + 8.4 + 8.4 + 8
                if (lo > width) {
                    i++
                    j = 0
                }
            }
            if (labels[i] === undefined) {
                labels[i] = []
            }
            labels[i][j++] = label[index]
        }

        for (let index in labels) {
            html [htmlIndex++] =
                <Row key={htmlIndex} className={labelClass}>
                    <Col xs={xs} sm={sm} md={md}>
                        <Space align="center" className={labelSpaceClass}>
                            {labelChild(labels[index])}
                        </Space>
                    </Col>
                </Row>

        }
        return (html.map(h => h))
    }

    return labels()
}

export default KKLables;